<template>
  <div class="o-spots-manual">
    <component
      :is="content.nzSettingLayout === 'List' ? 'spots-list' : 'spots-carousel'"
      :spots="content.spots"
      :headline="content.headline"
      :image="content.image"
      :image-alt-text="content.imageAltText"
      :is-aligned-right="content.nzSettingLayout === 'Carousel - Image right'"
    />
  </div>
</template>

<script>
import SpotsCarousel from '@/components/molecules/SpotsCarousel';
import SpotsList from '@/components/molecules/SpotsList';
import contentMixin from '@/mixins/contentMixin';

export default {
  name: 'SpotsManual',
  components: {
    SpotsCarousel,
    SpotsList
  },
  mixins: [contentMixin],
};
</script>
